exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-contentful-accessoires-sub-category-slug-js": () => import("./../../../src/pages/{ContentfulAccessoiresSubCategory.slug}.js" /* webpackChunkName: "component---src-pages-contentful-accessoires-sub-category-slug-js" */),
  "component---src-pages-contentful-ladies-collection-slug-js": () => import("./../../../src/pages/{ContentfulLadiesCollection.slug}.js" /* webpackChunkName: "component---src-pages-contentful-ladies-collection-slug-js" */),
  "component---src-pages-contentful-men-collection-slug-js": () => import("./../../../src/pages/{ContentfulMenCollection.slug}.js" /* webpackChunkName: "component---src-pages-contentful-men-collection-slug-js" */),
  "component---src-pages-contentful-menu-item-slug-js": () => import("./../../../src/pages/{ContentfulMenuItem.slug}.js" /* webpackChunkName: "component---src-pages-contentful-menu-item-slug-js" */),
  "component---src-pages-contentful-product-slug-js": () => import("./../../../src/pages/{ContentfulProduct.slug}.js" /* webpackChunkName: "component---src-pages-contentful-product-slug-js" */),
  "component---src-pages-demo-day-js": () => import("./../../../src/pages/demo-day.js" /* webpackChunkName: "component---src-pages-demo-day-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

